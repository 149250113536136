import { createContext, useContext, useState } from 'react';
import { Auth } from 'aws-amplify';

import _ from 'lodash';
import IUser from '../types/IUser';

import { aspireApi } from '../api';

export type UserContextValue = {
  currentUser: IUser | undefined;
  isFetchingCurrentUser: boolean;
  isFetchingCurrentSSOUser: boolean;
  fetchCurrentUser: () => Promise<IUser | undefined>;
  signIn: (email: string, password: string) => Promise<IUser | undefined>;
  setCurrentSSOUser: () => Promise<IUser | undefined>;
};

const UserContext = createContext<undefined | UserContextValue>(undefined);

export const useUser = () => useContext(UserContext);

const UserProvider = ({ children }: any) => {
  const [isFetchingCurrentUser, setIsFetchingUser] = useState(true);
  const [isFetchingCurrentSSOUser, setIsFetchingCurrentSSOUser] = useState(true);
  const [currentUser, setCurrentUser] = useState();

  /* eslint-disable consistent-return */
  const fetchCurrentUser = async (): Promise<IUser | undefined> => {
    try {
      if (currentUser !== undefined) {
        return;
      }

      setIsFetchingUser(true);

      const res = await aspireApi.get('/users/me');
      setCurrentUser(res.data);
      setIsFetchingUser(false);
      return res.data as IUser;
    } catch (error) {
      setIsFetchingUser(false);
    }
  };

  const signIn = async (email: string, password: string): Promise<IUser | undefined> => {
    try {
      setIsFetchingUser(true);

      const res = await aspireApi.post('/signIn', { username: email, password });
      setCurrentUser(res.data);
      setIsFetchingUser(false);
      return res.data as IUser;
    } catch (error) {
      setIsFetchingUser(false);
    }
  };

  const setCurrentSSOUser = async (): Promise<IUser | undefined> => {
    if (currentUser !== undefined) {
      return;
    }

    try {
      setIsFetchingCurrentSSOUser(true);

      const cognitoUserInfo = await Auth.currentUserInfo();
      const cognitoSession = await Auth.currentSession();
      const accessToken = cognitoSession.getAccessToken().getJwtToken();
      const payload = {
        email: _.get(cognitoUserInfo, 'attributes.email'),
        firstName: _.get(cognitoUserInfo, 'attributes.given_name'),
        lastName: _.get(cognitoUserInfo, 'attributes.family_name'),
        accessToken,
      };

      const res = await aspireApi.post('/signInSso', payload);
      setCurrentUser(res.data);
      setIsFetchingCurrentSSOUser(false);
      return res.data as IUser;
    } catch (error) {
      setIsFetchingCurrentSSOUser(false);
    }
  };
  /* eslint-enable consistent-return */

  return (
    <UserContext.Provider
      value={{
        currentUser,
        isFetchingCurrentUser,
        isFetchingCurrentSSOUser,
        fetchCurrentUser,
        signIn,
        setCurrentSSOUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
