import ReactGA from 'react-ga';
import IUser from '../types/IUser';

export const initGoogleAnalytics = (user: IUser | undefined, currentPath: string) => {
  if (user) {
    const trackingId = user.tenant.uiPreferences.googleAnalyticsTrackingId;
    if (trackingId) {
      ReactGA.initialize(trackingId, {
        gaOptions: {
          userId: user.id,
          siteSpeedSampleRate: 100,
        },
      });
      ReactGA.pageview(currentPath);
    }
  }
};
