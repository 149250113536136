import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Loading from '../components/common/Loading';
import { UserContextValue, useUser } from '../stores/UserStore';
import IUser from '../types/IUser';
import { initGoogleAnalytics } from '../util/ga';

export const LoginSSO = () => {
  const history = useHistory();
  const location = useLocation();

  const {
    isFetchingCurrentSSOUser,
    setCurrentSSOUser,
    currentUser,
  } = useUser() as UserContextValue;

  useEffect(() => {
    setCurrentSSOUser()
      .then((user: IUser | undefined) => {
        const currentPath = location.pathname + location.search;
        initGoogleAnalytics(user, currentPath);
      });
  }, []);

  if (isFetchingCurrentSSOUser) {
    return (
      <Loading message="Signing In" />
    );
  }

  if (!currentUser) {
    history.push('/error');
    return null;
  }

  history.push('/');
  return null;
};
